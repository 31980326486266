<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-31 14:54:41
 * @LastEditTime: 2022-04-08 17:44:04
 * @LastEditors: cyy
 * @Description: 批量导入
 * @FilePath: \teacher.dingdingkaike\src\views\MarketingCenter\promoter\batchImport.vue
-->
<template>
  <div id="batchImport">
    <el-form label-width="100px" class="demo-ruleForm" label-position="left">
      <el-form-item label="导入到分组" :required="true">
        <el-select
          v-model="p_group_id"
          style="width: 360px"
          placeholder="请选择分组"
        >
          <el-option
            v-for="item in groupList"
            :key="item.p_group_id"
            :label="item.name"
            :value="item.p_group_id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="header">
      <div class="top">
        <input
          ref="inputExcel"
          type="file"
          v-show="false"
          class="file"
          @change="fileChange"
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv"
        />
        <el-button type="primary" @click="selectFile">上传文件导入</el-button>
        <a href="https://f.dingdingkaike.com.cn/excelTemplate/promoter.csv">
          <el-button type="text" class="ml20 mr30">下载模板</el-button>
        </a>
      </div>
      <div class="tip">
        仅支持CSV格式文件，单次上传大小不超过10M，行数不超过1万，若超出限制，请分批导入；为避免乱码或导入异常，请使用Office2010或以上版本，请严格按照模板内容填写数据
      </div>
    </div>
    <pagination2 url="/Promoter/exportList" ref="childDialogBox">
      <template v-slot:default="{ tableData }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="导入时间">
            <template slot-scope="{ row }">
              {{ row.c_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column label="分组">
            <template slot-scope="{ row }">
              {{ row.name }}
            </template>
          </el-table-column>
          <el-table-column label="操作人">
            <template slot-scope="{ row }">
              <div class="flex-center">
                <div class="tu">
                  <img :src="row.uphoto" alt="" />
                </div>
                <div class="name" :title="row.uname">
                  {{ row.uname }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="导入数量">
            <template slot-scope="{ row }">
              {{ row.import_num }}
            </template>
          </el-table-column>
          <el-table-column label="成功数">
            <template slot-scope="{ row }">
              {{ row.success_num }}
            </template>
          </el-table-column>
          <el-table-column label="失败数">
            <template slot-scope="{ row }">
              {{ row.fail_num }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <el-button
                v-if="row.fail_num > 0"
                type="text"
                @click="downFail(row)"
              >
                下载失败模板
              </el-button>
              <span v-else>-</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
  </div>
</template>
 

<script>
export default {
  name: 'batchImport',

  data() {
    return {
      groupList: [],

      p_group_id: '',
    }
  },

  created() {
    this.getgroupList()
  },

  methods: {
    // 下载失败模板
    downFail(row) {
      let eleLink = document.createElement('a')
      eleLink.href = `${location.origin}/Promoter/downFail?p_import_id=${row.p_import_id}`
      eleLink.click()
      eleLink.remove()
    },

    // 触发input
    selectFile() {
      if (this.p_group_id) {
        this.$refs.inputExcel.click()
      } else {
        this.$root.prompt('请选择分组')
      }
    },

    // 选择文件后提交
    fileChange(e) {
      const file = e.target.files[0]
      const maxSize = 10
      if (file.size > 1024 * 1024 * maxSize) {
        this.$root.prompt('上传文件大于10M')
        return
      }
      this.file = file

      this.excelRunimport()
    },

    // 重置
    rest() {
      this.$refs.childDialogBox.reset()
    },

    async excelRunimport() {
      let param = new FormData()
      param.append('file', this.file)
      param.append('p_group_id', this.p_group_id)

      var request = new XMLHttpRequest()
      request.open('post', `${location.origin}/Promoter/excel_runimport`)
      request.send(param)

      request.onload = () => {
        try {
          const response = JSON.parse(request.response)
          if (request.status == 200 && response.code == 200) {
            this.$root.prompt({
              msg: '操作成功',
              type: 'success',
            })
          } else {
            throw response.info
          }
        } catch (e) {
          this.$root.prompt({
            msg: typeof e === 'string' ? e : '上传失败',
          })
        }

        this.$refs.inputExcel.value = ''
        this.rest()
      }
    },

    // 推广员分组
    async getgroupList() {
      const { data } = await this.$http({
        url: '/Promoter/groupList',
        data: {},
      })
      this.groupList = data
    },
  },
}
</script>

<style lang="scss" scoped>
#batchImport {
  background-color: #fff;
  padding: 20px;
  img {
    width: 100%;
    height: 100%;
  }
  .header {
    display: flex;
    align-content: center;
    margin-bottom: 20px;
    .top {
      flex-shrink: 0;
    }
    .tip {
      font-size: 14px;
      color: #666666;
      line-height: 1.5;
    }
  }
  .tu {
    width: 22px;
    height: 22px;
    border-radius: 11px;
    margin-right: 10px;
  }
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>>
 